// import { acceptHMRUpdate, defineStore } from 'pinia'

export function updateTheme(themeName: string, themeColor: string) {
	console.log('updateTheme themeName:', themeName, 'themeColor:', themeColor)

	return `https://cdn.jsdelivr.net/npm/primevue@3.15.0/resources/themes/${themeName}-${themeColor}/theme.min.css`
}
type ImageKey = 'logo_login' | 'logo_login_title' | 'logo_home';
export const useThemeStore = defineStore('theme', {
	// a function that returns a fresh state
	state: () => ({
		imageCollections: {
			logo_login: '',
			logo_login_title: '',
			logo_home: ''
		},
		themeName: 'vela',
		themeColor: 'blue',
		link: ''
		// link: 'https://cdn.jsdelivr.net/npm/primevue@3.15.0/resources/themes/vela-blue/theme.min.css'
		/* themeName: 'vela',
		themeColor: 'blue',
		link: 'https://cdn.jsdelivr.net/npm/primevue@latest/resources/themes/vela-blue/theme.css' */
	}),
	// optional getters
	getters: {
		theme: (state) => {
			return `${state.themeName}-${state.themeColor}`
		},
		isDarkMode: state => state.themeName !== 'saga',
		getLogoModeDarkLight: (state) => { //  creata ma non usata
			if (state.themeName === 'saga') {
				return appConfig.infoapp.imgLogo.replace('light', 'dark')
			} else {
				return appConfig.infoapp.imgLogo.replace('dark', 'light')
			}
		},
		getLogo: (state) => {
			if (state.imageCollections.logo_home) {
				return state.imageCollections.logo_home
			} else {
				return appConfig.infoapp.imgLogo
			}
		},
		getLogoClass: (state) => {
			if (!state.imageCollections.logo_home) {
				return 'ml-2'
			}
			return ''
		},
		getImageCollections: (state) => {
			return state.imageCollections
		},
		getImageByName: state => (name: ImageKey) => {
			return state.imageCollections[name]
		},
		getLoadLogoHome: (state) => {
			return state.imageCollections.logo_home !== ''
		}
	},
	// optional actions
	actions: {
		setImageCollectionsFromUser(jsonValue?: string) {
			if (!jsonValue) {
				return
			}
			const decodeJSON = JSON.parse(jsonValue)
			this.imageCollections.logo_login = decodeJSON.logo_login
			this.imageCollections.logo_login_title = decodeJSON.logo_login_title
			this.imageCollections.logo_home = decodeJSON.logo_home
		},
		setOptionFromUser(jsonValue?: string) {
			if (!jsonValue) {
				updateTheme(this.themeName, this.themeColor)
				return
			}
			const { themeColor, themeMode } = JSON.parse(jsonValue)
			if (themeColor) {
				const nameThemeColorTheme = themeColor.split('-')
				this.themeColor = nameThemeColorTheme[1]
				this.themeName = nameThemeColorTheme[0]
				this.link = updateTheme(this.themeName, this.themeColor)
			}
			if (themeMode === 'light') {
				this.setLight()
			} else { // if (themeMode === 'dark') {
				this.setDim()
			}
			/* this.themeColor = themeColor
			this.themeName = themeMode
			this.link = updateTheme(this.themeName, this.themeColor) */
		},
		setDark() {
			this.themeName = 'arya'
			this.link = updateTheme(this.themeName, this.themeColor)
		},
		setDim() {
			this.themeName = 'vela'
			this.link = updateTheme(this.themeName, this.themeColor)
		},
		setLight() {
			this.themeName = 'saga'
			this.link = updateTheme(this.themeName, this.themeColor)
		},
		setColor(colorName: string) {
			this.themeColor = colorName
			this.link = updateTheme(this.themeName, this.themeColor)
		}
	}
})

// if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useThemeStore, import.meta.hot)) }
